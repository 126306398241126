import {
  moonbaseAlpha,
  moonbeam,
  moonriver,
} from '@moonbeam-network/xcm-config';
import { atom } from 'recoil';
import { createLocalStorageEffect } from 'recoil/effects';
import { ImportedTokens } from './interfaces';

export const importedTokensAtom = atom<ImportedTokens>({
  key: 'importedTokens',
  default: {
    [moonbeam.key]: [],
    [moonriver.key]: [],
    [moonbaseAlpha.key]: [],
  },
  effects: [createLocalStorageEffect('imported-tokens')],
});
