import { Alert, Text, useMantineTheme } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { getLinkColor } from 'components/pages/overview/Announcements/Announcement';
import { useStakingAnnouncementFlag } from 'hooks/flags/useStakingAnnouncementFlag';
import classes from './Announcement.module.css';
export const StakingAnnouncement = () => {
  const theme = useMantineTheme();
  const {
    isEnabled,
    announcement
  } = useStakingAnnouncementFlag();
  if (!isEnabled || !announcement) return null;
  return <Alert radius={'lg'} maw={600} className={classes.paperImportant} p={'sm'} pl={'md'} data-sentry-element="Alert" data-sentry-component="StakingAnnouncement" data-sentry-source-file="StakingAnnouncement.tsx">
      <Text ta={'center'} data-sentry-element="Text" data-sentry-source-file="StakingAnnouncement.tsx">
        <OffsiteLink size={'md'} link={announcement.link} label={announcement.text} c={getLinkColor(theme)} data-sentry-element="OffsiteLink" data-sentry-source-file="StakingAnnouncement.tsx" />
      </Text>
    </Alert>;
};