import { Box, Divider, Group, Text } from '@mantine/core';
import { MoonTokenAmount } from 'components/TokenAmount';
import { useBlockTimestampElapsedTime } from 'hooks/useBlockTime';
import { SubscanReward } from 'services/subscan';
interface Props {
  reward: SubscanReward;
  index: number;
}
export const StakingRewardRow = ({
  reward,
  index
}: Props) => {
  const elapsedTime = useBlockTimestampElapsedTime(reward.block_timestamp);
  const isFirst = index === 0;
  return <Box data-sentry-element="Box" data-sentry-component="StakingRewardRow" data-sentry-source-file="StakingRewardRow.tsx">
      {!isFirst && <Divider mr={'xs'} />}
      <Group justify={'space-between'} pb={'xs'} pt={isFirst ? 0 : 'xs'} data-sentry-element="Group" data-sentry-source-file="StakingRewardRow.tsx">
        <MoonTokenAmount amount={reward.amount} size={'sm'} maxDecimals={4} isHidable={true} data-sentry-element="MoonTokenAmount" data-sentry-source-file="StakingRewardRow.tsx" />
        <Text fw={'lighter'} size={'xs'} mr={'xs'} data-sentry-element="Text" data-sentry-source-file="StakingRewardRow.tsx">
          {elapsedTime}
        </Text>
      </Group>
    </Box>;
};