import { Anchor, Box, Card, Center, Group, LoadingOverlay, Stack, Text, Title, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Trans, useTranslation } from 'next-i18next';
import { useStakingRewards } from 'hooks/queries/staking/useStakingRewards';
import { useViewportSize } from '@mantine/hooks';
import { ActionButton } from 'components/ActionButton';
import { StakingRewardsList } from 'components/BalanceGrid/StakingRewards/StakingRewardsList';
import { MoonTokenAmount } from 'components/TokenAmount';
import { MoonTokenFiatPrice } from 'components/TokenFiatPrice/MoonTokenFiatPrice';
import { useMoonBalances } from 'hooks/queries/balances/useMoonChainBalances';
import { useRouteLink } from 'hooks/router/useRouteLink';
import { useIsMobile } from 'hooks/useMedia';
import { useThemeColors } from 'hooks/useThemeColors';
import Link from 'next/link';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoArrowBackOutline, IoArrowForwardOutline, IoHelpCircleOutline } from 'react-icons/io5';
import { calcHistoricalRewardRate } from 'utils/calc';
import { Chart } from './Chart';
import classes from './StakingRewards.module.css';
const daysToShow = 7;
export interface Props {
  address: string | undefined;
  showHistoricalRewardRate?: boolean;
}
export function StakingRewards({
  address,
  showHistoricalRewardRate
}: Props) {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile();
  const balances = useMoonBalances(address);
  const {
    isCurrentPath,
    fullPath
  } = useRouteLink('staking');
  const {
    width
  } = useViewportSize();
  const [showList, setShowList] = useState(false);
  const {
    isLoading,
    labels,
    hasFailed,
    rewards,
    latestRewards
  } = useStakingRewards(address, daysToShow);
  const {
    themeColors
  } = useThemeColors();
  const {
    other: {
      colors
    }
  } = useMantineTheme();
  const card = useRef(null);
  const [cardWidth, setCardWith] = useState(card.current?.['offsetWidth'] || 0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setCardWith(card.current?.['offsetWidth'] || 0);
  }, [card, width]);
  const totalRewards = useMemo(() => rewards.reduce((p, c) => p + c, 0n), [rewards]);
  const historicalRewardRate = useMemo(() => showHistoricalRewardRate ? calcHistoricalRewardRate(totalRewards, balances?.lockedStaking) : undefined, [totalRewards, balances, showHistoricalRewardRate]);
  const showStakingLink = balances?.lockedStaking === 0n && totalRewards === 0n && !isCurrentPath;
  const detailsButtonLabel = showList ? 'hideDetails' : 'moreDetails';
  const hideChartWidthTreshold = showHistoricalRewardRate ? 720 : 600;
  const hideChart = showList && cardWidth <= hideChartWidthTreshold;
  return <Card p={'xl'} pb={8} ref={card} data-sentry-element="Card" data-sentry-component="StakingRewards" data-sentry-source-file="StakingRewards.tsx">
      <Group justify={'space-between'} mb={'lg'} data-sentry-element="Group" data-sentry-source-file="StakingRewards.tsx">
        <Title order={2} size={'h4'} data-sentry-element="Title" data-sentry-source-file="StakingRewards.tsx">
          {t('latestStakingRewards')}
        </Title>
        {latestRewards.length && <UnstyledButton onClick={() => setShowList(!showList)} aria-label={detailsButtonLabel}>
            <Group gap={'xs'}>
              <Text>{t(detailsButtonLabel)}</Text>
              {showList ? <IoArrowBackOutline color={colors.action} /> : <IoArrowForwardOutline color={colors.action} />}
            </Group>
          </UnstyledButton>}
      </Group>
      <div className={classes.contentDiv}>
        <LoadingOverlay visible={isLoading} overlayProps={{
        opacity: 0,
        blur: 1
      }} zIndex={100} data-testid={'staking-rewards-loading-overlay'} data-sentry-element="LoadingOverlay" data-sentry-source-file="StakingRewards.tsx" />
        {showStakingLink ? <Center h={'100%'}>
            <Anchor component={Link} href={fullPath} td={'none'}>
              <Box>
                <ActionButton>{t('startStaking')}</ActionButton>
              </Box>
            </Anchor>
          </Center> : hasFailed ? <Text size={'sm'} ta={'center'}>
            {t('subscanConnectionError')}
          </Text> : <Group justify={'space-between'}>
            <Box w={!showList ? '100%' : cardWidth - 290}>
              <Group justify={'space-between'} gap={'xl'}>
                <Stack gap={0}>
                  <MoonTokenAmount amount={totalRewards} maxDecimals={6} fz={24} isHidable={true} lh={1} />

                  <MoonTokenFiatPrice amount={totalRewards} c={themeColors.texts} fz={'sm'} isHidable={true} />

                  <Text size={'sm'}>
                    {t('latestStakingRewardsDays', {
                  days: daysToShow
                })}
                  </Text>
                </Stack>
                <Stack gap={0}>
                  {historicalRewardRate && <Group gap={'xs'}>
                      {t(isMobile ? 'HRR' : 'staking:historicalRewardRate')}

                      <Tooltip label={<Trans i18nKey={'staking:historicalRewardRateTooltip'} components={{
                  bold: <Text fw={'bolder'} ta={'center'} />
                }} />} w={400} multiline withArrow>
                        <div>
                          <IoHelpCircleOutline style={{
                      verticalAlign: 'middle'
                    }} />
                        </div>
                      </Tooltip>
                    </Group>}
                  {historicalRewardRate && <Text size={'xl'} ta={'end'}>
                      {historicalRewardRate} {'%'}
                    </Text>}
                </Stack>
              </Group>
              <Box className={classes.chartDiv} hidden={hideChart}>
                <Chart rewards={rewards} labels={labels} />
              </Box>
            </Box>
            <StakingRewardsList show={showList} address={address} latestRewards={latestRewards} />
          </Group>}
      </div>
    </Card>;
}