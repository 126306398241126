import Big from 'big.js';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useFormatValue(
  value: Big | string | number | undefined,
  maxDecimals?: number,
  noFormat = false,
): string | undefined {
  const { locale = 'en' } = useRouter();

  return useMemo(() => {
    // biome-ignore lint/suspicious/noDoubleEquals: <explanation>
    if (value == undefined) {
      return;
    }

    const big = new Big(value);

    if (noFormat) {
      return big.toString();
    }

    const number = big.toNumber();
    const standardDecimalLogic = number > 1 ? 2 : number < 0.1 ? 5 : 4;
    // number > 1
    // 12.3456        12.35
    // 1.23456         1.23

    // 0.1 <= number < 1
    // 0.123456        0.1234

    // number < 0.1
    // 0.0123456       0.01234
    // 0.00123456      0.00123
    // 0.000123456     0.00012
    // 0.0000123456    0.00001

    return number.toLocaleString(locale, {
      maximumFractionDigits: maxDecimals || standardDecimalLogic,
    });
  }, [value, noFormat, locale, maxDecimals]);
}
