import { AssetAmount } from '@moonbeam-network/xcm-types';
import { useFormatValue } from 'hooks/useFormatValue';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useCoinGeckoPrices } from 'recoil/coingecko';
import { useUserSettings } from 'recoil/userSettings';
import { getCoinGeckoIdByAssetKey } from 'services/coingecko';
import { calcTokenConversion } from 'utils/calc';

export const FIAT_PRECISION = 6;

export function useFiatSymbol(): string | undefined {
  const { locale = 'en_US' } = useRouter();
  const { fiatCurrency } = useUserSettings();

  return useMemo(
    () =>
      Intl.NumberFormat(locale, {
        style: 'currency',
        currency: fiatCurrency,
      })
        .formatToParts(0)
        .find((part) => part.type === 'currency')?.value,
    [fiatCurrency, locale],
  );
}

export function useFiatConversion(
  assetAmount?: AssetAmount,
  maxDecimals?: number,
  showPrice = false,
  noFormat = false,
): [string | undefined, string | undefined] {
  const fiatPrices = useCoinGeckoPrices();
  const { fiatCurrency } = useUserSettings();
  const fiatSymbol = useFiatSymbol();
  const network = useMoonChainKeyParam();
  const coinGeckoId = getCoinGeckoIdByAssetKey(network, assetAmount?.key);

  const fiatPrice = coinGeckoId
    ? fiatPrices?.[coinGeckoId]?.[fiatCurrency]
    : undefined;

  const value = useMemo(() => {
    if (!assetAmount || !fiatPrice) {
      return;
    }

    return calcTokenConversion(assetAmount, fiatPrice, maxDecimals);
  }, [assetAmount, fiatPrice, maxDecimals]);

  return [
    useFormatValue(showPrice ? fiatPrice : value, maxDecimals, noFormat),
    fiatSymbol,
  ];
}
