import { coinGeckoIds } from 'configs/coinGecko';
import fetchRetry from 'fetch-retry';
import ls from 'localstorage-slim';
import type { ImportedTokens } from 'recoil/importedTokens';
import { FiatCurrencies } from 'types/fiatCurrencies';
import { CoinGeckoPricesResponse } from './coingecko.types';

export function getCoinGeckoIdByAssetKey(
  moonChainKey: string,
  assetKey: string | undefined,
): string | undefined {
  return assetKey ? coinGeckoIds[moonChainKey]?.[assetKey] : undefined;
}

export function getPermanentCoinGeckoIds(): string[] {
  const assetsDicts = Object.values(coinGeckoIds);

  return assetsDicts.reduce(
    (assetsList, networkAssets) => [
      ...assetsList,
      ...Object.values(networkAssets),
    ],
    [] as string[],
  );
}

export function getImportedCoinGeckoIds(
  importedTokens: ImportedTokens,
): string[] {
  const importedTokensLists = Object.values(importedTokens);

  return importedTokensLists
    .flat()
    .map((token) => token.coinGeckoId || '')
    .filter((id) => id.length);
}

export async function getCoinGeckoPrices(
  coinGeckoIds: string[],
): Promise<CoinGeckoPricesResponse> {
  const url = new URL('https://api.coingecko.com/api/v3/simple/price');
  const dedupedIds = [...new Set(coinGeckoIds)];

  url.searchParams.append(
    'vs_currencies',
    Object.values(FiatCurrencies).join(','),
  );

  url.searchParams.append('ids', dedupedIds.join(','));

  const cacheKey = url.toString();
  const cachedResponse = ls.get<CoinGeckoPricesResponse>(cacheKey);

  if (cachedResponse) return cachedResponse;

  // Try needed in CoinGecko might return 429 Too Many Request
  // without Access-Control-Allow-Origin header, making fetch to
  // throw TypeError: Failed to fetch
  // Design pending on how to show the error
  try {
    const response = await fetchRetry(fetch)(url.toString(), {
      retries: 5,
      retryDelay: 10000,
      retryOn: [429, 503],
    });

    const pricesResponse = {
      responseOk: response.ok,
      prices: await response.json(),
    };

    ls.set(cacheKey, pricesResponse, { ttl: 600 });

    return pricesResponse;
  } catch (e) {
    return {
      responseOk: false,
      error: (e as Error).message,
    };
  }
}

export async function checkCoinGeckoId(coinGeckoId: string): Promise<boolean> {
  const url = new URL(`https://api.coingecko.com/api/v3/coins/${coinGeckoId}`);

  const response = await fetchRetry(fetch)(url.toString(), {
    retries: 5,
    retryDelay: 8000,
    retryOn: [429, 503],
  });

  return response.ok;
}
