import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { importedTokensAtom } from 'recoil/importedTokens';
import {
  getCoinGeckoPrices,
  getImportedCoinGeckoIds,
  getPermanentCoinGeckoIds,
} from 'services/coingecko';
import { CoinGeckoPrices } from 'services/coingecko/coingecko.types';

const permanentIds = getPermanentCoinGeckoIds();

export function useCoinGeckoPrices(): CoinGeckoPrices | undefined {
  const [prices, setPrices] = useState<CoinGeckoPrices | undefined>();
  const importedTokens = useRecoilValue(importedTokensAtom);

  useEffect(() => {
    const importedTokensIds = getImportedCoinGeckoIds(importedTokens);

    getCoinGeckoPrices([...permanentIds, ...importedTokensIds]).then(
      (pricesResponse) => {
        if (!pricesResponse) {
          return;
        }

        if (pricesResponse.responseOk) {
          setPrices(pricesResponse.prices);
        } else {
          console.error(pricesResponse.error);
        }
      },
    );
  }, [importedTokens]);

  return prices;
}
