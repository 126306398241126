import { Loader, Text, TextProps, Tooltip, useMantineTheme } from '@mantine/core';
import { AssetAmount } from '@moonbeam-network/xcm-types';
import { useFiatConversion } from 'hooks/useFiatConversion';
import { useIsMounted } from 'hooks/useIsMounted';
import { useTranslation } from 'next-i18next';
import { Suspense } from 'react';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { useUserSettings } from 'recoil/userSettings';
interface Props extends TextProps {
  assetAmount: AssetAmount;
  showPrice?: boolean;
  maxDecimals?: number;
  isHidable?: boolean;
}
export function TokenFiatPriceContent({
  assetAmount,
  maxDecimals,
  showPrice = false,
  isHidable = false,
  ...textProps
}: Props) {
  const {
    t
  } = useTranslation();
  const isMounted = useIsMounted();
  const {
    areBalancesVisible
  } = useUserSettings();
  const [fiatConversion, fiatSymbol] = useFiatConversion(assetAmount, maxDecimals, showPrice);
  if (!isMounted) {
    return null;
  }
  const isHidden = isHidable && !areBalancesVisible;
  return <Tooltip disabled={!!fiatConversion || isHidden} label={t('priceNotFound')} data-sentry-element="Tooltip" data-sentry-component="TokenFiatPriceContent" data-sentry-source-file="TokenFiatPrice.tsx">
      <Text span {...textProps} data-sentry-element="Text" data-sentry-source-file="TokenFiatPrice.tsx">
        {fiatSymbol}
        {isHidden ? '****' : fiatConversion ? fiatConversion : <>
            {'-.- '}
            <IoHelpCircleOutline style={{
          verticalAlign: 'middle'
        }} />
          </>}
      </Text>
    </Tooltip>;
}
export function TokenFiatPrice(props: Props) {
  const theme = useMantineTheme();
  return <Suspense fallback={<Loader size={'xs'} color={theme.colors.gray[4]} />} data-sentry-element="Suspense" data-sentry-component="TokenFiatPrice" data-sentry-source-file="TokenFiatPrice.tsx">
      <TokenFiatPriceContent {...props} data-sentry-element="TokenFiatPriceContent" data-sentry-source-file="TokenFiatPrice.tsx" />
    </Suspense>;
}