import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { SubscanReward, fetchSubscanRewards } from 'services/subscan';
import { formatDateShort } from 'utils/charts';

function getCutoffTimestamp(daysBack: number): number {
  const date = new Date();

  date.setDate(date.getDate() - daysBack + 1);

  return date.setHours(0, 0, 0, 0) / 1000;
}

function processRewards(
  subscanRewards: SubscanReward[],
  labels: string[],
  locale: string,
  cutoffTimestamp: number,
): { rewardsPerDay: bigint[]; latestRewards: SubscanReward[] } {
  const rewardsPerDay: bigint[] = Array(labels.length).fill(0n);

  const latestRewards: SubscanReward[] = [];
  const latestRewardsLimit = 12;

  subscanRewards
    .filter((reward, i) => {
      if (i < latestRewardsLimit) latestRewards.push(reward);

      return reward.block_timestamp > cutoffTimestamp;
    })
    .forEach((reward) => {
      const date = new Date(reward.block_timestamp * 1000);
      const index = labels.indexOf(formatDateShort(date, locale));

      rewardsPerDay[index] += BigInt(reward.amount);
    });

  return { rewardsPerDay, latestRewards };
}

export interface StakingRewards {
  isLoading: boolean;
  labels: string[];
  hasFailed: boolean;
  rewards: bigint[];
  latestRewards: SubscanReward[];
}

export function useStakingRewards(
  address: string | undefined,
  daysToShow: number,
): StakingRewards {
  const { locale = 'en_US' } = useRouter();
  const { subscanApiUrl } = useMoonChain();
  const [rewards, setRewards] = useState<bigint[]>(Array(daysToShow).fill(0n));
  const [latestRewards, setLatestRewards] = useState<SubscanReward[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const labels = useMemo(() => {
    return Array(daysToShow)
      .fill(undefined)
      .map((_, i) => {
        const date = new Date();

        date.setDate(new Date().getDate() - (daysToShow - i) + 1);

        return formatDateShort(date, locale);
      });
  }, [locale, daysToShow]);

  useEffect(() => {
    if (!address) return;

    const controller = new AbortController();
    const cutoffTimestamp = getCutoffTimestamp(daysToShow);

    (async (): Promise<void> => {
      setHasFailed(false);
      setIsLoading(true);

      try {
        const { rewardsPerDay, latestRewards } = processRewards(
          await fetchSubscanRewards(
            address,
            subscanApiUrl,
            controller.signal,
            cutoffTimestamp,
          ),
          labels,
          locale,
          cutoffTimestamp,
        );

        setRewards(rewardsPerDay);
        setLatestRewards(latestRewards);
        setIsLoading(false);
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          setHasFailed(true);
          setIsLoading(false);
        }
      }
    })();

    return (): void => {
      controller.abort();
    };
  }, [address, daysToShow, labels, locale, subscanApiUrl]);

  return {
    isLoading,
    labels,
    hasFailed,
    rewards,
    latestRewards,
  };
}
