import { Grid } from '@mantine/core';
import { useOverviewAnnouncementsFlag } from 'hooks/flags/useOverviewAnnouncementsFlag';
import { useIsMounted } from 'hooks/useIsMounted';
import { Announcement } from './Announcement';
export function Announcements() {
  const {
    isEnabled,
    announcements
  } = useOverviewAnnouncementsFlag();
  const isMounted = useIsMounted();
  if (!isEnabled || !isMounted || !announcements.length) return null;
  return <Grid data-sentry-element="Grid" data-sentry-component="Announcements" data-sentry-source-file="Announcements.tsx">
      {announcements.map((ann, ii) => <Grid.Col key={ii} span={{
      sm: 12,
      md: 6,
      xl: 4
    }}>
          <Announcement announcement={ann} isImportant={ann.important || ii === 0} />
        </Grid.Col>)}
    </Grid>;
}