import { Grid } from '@mantine/core';
import { useIsMounted } from 'hooks/useIsMounted';
import { Balance } from './Balance';
import { StakingRewards } from './StakingRewards';
export interface Props {
  address: string | undefined;
  isStakingPage?: boolean;
  showHistoricalRewardRate?: boolean;
}
export const BalanceGrid = ({
  address,
  isStakingPage,
  showHistoricalRewardRate
}: Props) => {
  const isMounted = useIsMounted();
  if (!isMounted) return null;
  return <Grid data-sentry-element="Grid" data-sentry-component="BalanceGrid" data-sentry-source-file="BalanceGrid.tsx">
      <Grid.Col span={{
      sm: 12,
      md: 12,
      lg: 5,
      xl: 4
    }} data-sentry-element="unknown" data-sentry-source-file="BalanceGrid.tsx">
        <Balance address={address} isStakingPage={isStakingPage} data-sentry-element="Balance" data-sentry-source-file="BalanceGrid.tsx" />
      </Grid.Col>
      <Grid.Col span={{
      sm: 12,
      md: 12,
      lg: 7,
      xl: 8
    }} data-sentry-element="unknown" data-sentry-source-file="BalanceGrid.tsx">
        <StakingRewards address={address} showHistoricalRewardRate={showHistoricalRewardRate} data-sentry-element="StakingRewards" data-sentry-source-file="BalanceGrid.tsx" />
      </Grid.Col>
    </Grid>;
};