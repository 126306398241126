import { Anchor, BackgroundImage, Group, MantineTheme, Paper, Stack, Title, useMantineTheme } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { OverviewAnnouncement } from 'hooks/flags/useOverviewAnnouncementsFlag';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { IoArrowForward } from 'react-icons/io5';
import classes from './Announcement.module.css';
export interface Props {
  announcement: OverviewAnnouncement;
  isImportant: boolean;
}
export const getLinkColor = (theme: MantineTheme, isImportant = true): string => isImportant ? theme.other.colors.announcementsLink.important : theme.other.colors.announcementsLink.regular;
export function Announcement({
  announcement: {
    title,
    link,
    image = ''
  },
  isImportant
}: Props) {
  const {
    t
  } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const theme = useMantineTheme();
  return <Paper className={isImportant ? classes.paperImportant : classes.paper} p={0} data-sentry-element="Paper" data-sentry-component="Announcement" data-sentry-source-file="Announcement.tsx">
      <BackgroundImage src={image} radius={'md'} p={'xl'} style={{
      backgroundPosition: 'top'
    }} data-sentry-element="BackgroundImage" data-sentry-source-file="Announcement.tsx">
        <Stack justify={'space-between'} mih={100} data-sentry-element="Stack" data-sentry-source-file="Announcement.tsx">
          <Title order={2} size={'h3'} className={isImportant ? classes.titleImportant : classes.title} data-sentry-element="Title" data-sentry-source-file="Announcement.tsx">
            {title}
          </Title>
          {link && <>
              {link.url && <OffsiteLink link={link.url} label={link.label || t('getMoreInfo')} className={classes.link} fw={'bold'} size={'md'} c={getLinkColor(theme, isImportant)} />}
              {link.path && <Link href={{
            pathname: `/[moonChainKey]${link.path}`,
            query: {
              moonChainKey
            }
          }} passHref>
                  <Anchor fw={'bold'} className={classes.link}>
                    <Group gap={0}>
                      {link.label || t('getMoreInfo')}
                      <IoArrowForward size={'20'} style={{
                  marginLeft: 10
                }} />
                    </Group>
                  </Anchor>
                </Link>}
            </>}
        </Stack>
      </BackgroundImage>
    </Paper>;
}