import { px, useMantineTheme } from '@mantine/core';
import { toDecimal } from '@moonbeam-network/xcm-utils';
import { ArcElement, ChartData, Chart as ChartJS, ChartOptions, Legend, Tooltip } from 'chart.js';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useUserSettings } from 'recoil/userSettings';
import { getDiagonalPattern } from 'utils/charts';
ChartJS.register(ArcElement, Legend, Tooltip);
export function Chart({
  free,
  lockedStaking,
  lockedDemocracy
}: {
  free: bigint;
  lockedStaking: bigint;
  lockedDemocracy: bigint;
}) {
  const {
    t
  } = useTranslation();
  const theme = useMantineTheme();
  const isDark = useIsDarkMode();
  const {
    decimals
  } = useMoonChain();
  const {
    areBalancesVisible
  } = useUserSettings();
  const {
    themeColors
  } = useThemeColors();
  const chartColor = isDark ? theme.other.colors.balanceChartDark : theme.other.colors.balanceChartLight;
  const chartOptions = useMemo((): ChartOptions<'doughnut'> => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          usePointStyle: true,
          padding: 16,
          boxHeight: 8,
          boxWidth: 10,
          filter: label => label.text !== t('lockedStaked'),
          color: themeColors.texts,
          font: {
            family: theme.fontFamily,
            weight: 700,
            size: Number(px(theme.fontSizes.md))
          }
        }
      },
      tooltip: {
        enabled: areBalancesVisible,
        backgroundColor: theme.other.colors.tooltip
      }
    },
    cutout: 40
  }), [t, theme, areBalancesVisible, themeColors]);
  const data = useMemo((): ChartData<'doughnut'> => {
    const sharedLockBalance = lockedDemocracy < lockedStaking ? lockedDemocracy : lockedStaking;
    const colors = [chartColor[0], chartColor[1], getDiagonalPattern(chartColor[1], chartColor[2]), chartColor[2]];
    return {
      labels: [t('free'), t('reserved'), t('lockedStaked'), t('locked')],
      datasets: [{
        data: [+toDecimal(free, decimals, 6), +toDecimal(lockedStaking - sharedLockBalance, decimals, 6), +toDecimal(sharedLockBalance, decimals, 6), +toDecimal(lockedDemocracy - sharedLockBalance, decimals, 6)],
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 0
      }]
    };
  }, [lockedDemocracy, lockedStaking, chartColor, t, free, decimals]);
  return <Doughnut data={data} options={chartOptions} data-sentry-element="Doughnut" data-sentry-component="Chart" data-sentry-source-file="Chart.tsx" />;
}