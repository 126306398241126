import { TextProps } from '@mantine/core';
import { AssetAmount } from '@moonbeam-network/xcm-types';
import { TokenFiatPrice } from 'components/TokenFiatPrice/TokenFiatPrice';
import { useMoonChain } from 'hooks/useMoonChainConfig';
interface Props extends TextProps {
  amount: bigint;
  maxDecimals?: number;
  isHidable?: boolean;
}
export function MoonTokenFiatPrice({
  amount,
  ...props
}: Props) {
  const {
    asset,
    decimals
  } = useMoonChain();
  const assetAmount = AssetAmount.fromAsset(asset, {
    amount,
    decimals
  });
  return <TokenFiatPrice assetAmount={assetAmount} {...props} data-sentry-element="TokenFiatPrice" data-sentry-component="MoonTokenFiatPrice" data-sentry-source-file="MoonTokenFiatPrice.tsx" />;
}