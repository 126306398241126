import { Anchor, Box, Card, Divider, Group, Text, Title, Tooltip } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { MoonTokenAmount } from 'components/TokenAmount';
import { useMoonBalances } from 'hooks/queries/balances/useMoonChainBalances';
import { useRouteLink } from 'hooks/router/useRouteLink';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { IoInformationCircleOutline } from 'react-icons/io5';
import classes from './Balance.module.css';
import { Chart } from './Chart';
export interface Props {
  address: string | undefined;
  isStakingPage?: boolean;
}
export function Balance({
  address,
  isStakingPage
}: Props) {
  const {
    t
  } = useTranslation();
  const balances = useMoonBalances(address);
  const {
    isCurrentPath,
    fullPath
  } = useRouteLink('staking');
  return <Card p={'xl'} className={classes.card} data-sentry-element="Card" data-sentry-component="Balance" data-sentry-source-file="Balance.tsx">
      <Title order={2} size={'h4'} mb={'lg'} data-sentry-element="Title" data-sentry-source-file="Balance.tsx">
        {t('balance')}
      </Title>
      <div className={classes.chartBox}>
        {!!balances && <Chart free={balances.free} lockedStaking={balances.lockedStaking} lockedDemocracy={balances.lockedDemocracy} />}
      </div>
      <Divider mt={'lg'} data-sentry-element="Divider" data-sentry-source-file="Balance.tsx" />
      <Text mt={'sm'} fw={'bold'} data-sentry-element="Text" data-sentry-source-file="Balance.tsx">
        {t('total')}
        <MoonTokenAmount span ml={'sm'} fw={'normal'} amount={balances?.total} isHidable={true} maxDecimals={6} data-sentry-element="MoonTokenAmount" data-sentry-source-file="Balance.tsx" />
      </Text>
      <Group mt={'sm'} justify={'space-between'} data-sentry-element="Group" data-sentry-source-file="Balance.tsx">
        <Text fw={'bold'} data-sentry-element="Text" data-sentry-source-file="Balance.tsx">
          {t(isStakingPage ? 'availableForStaking' : 'freeBalance')}
          <MoonTokenAmount span ml={'sm'} fw={'normal'} amount={isStakingPage ? balances?.availableForStaking : balances?.free} isHidable={true} maxDecimals={6} data-sentry-element="MoonTokenAmount" data-sentry-source-file="Balance.tsx" />
        </Text>
        <Tooltip label={t(isStakingPage ? 'availableForStakingTooltip' : 'freeBalanceTooltip')} multiline w={300} withArrow data-sentry-element="Tooltip" data-sentry-source-file="Balance.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="Balance.tsx">
            <IoInformationCircleOutline data-sentry-element="IoInformationCircleOutline" data-sentry-source-file="Balance.tsx" />
          </Box>
        </Tooltip>
      </Group>
      <Group mt={'sm'} justify={'space-between'} data-sentry-element="Group" data-sentry-source-file="Balance.tsx">
        <Text fw={'bold'} data-sentry-element="Text" data-sentry-source-file="Balance.tsx">
          {t('reservedStaking')}
          {balances?.lockedStaking === 0n && balances.availableForStaking > 0n && !isCurrentPath ? <Anchor component={Link} href={fullPath} td={'none'}>
              <Box display={'inline-block'}>
                <ActionButton size={'compact-sm'} ml={'sm'}>
                  {t('startStaking')}
                </ActionButton>
              </Box>
            </Anchor> : <MoonTokenAmount span ml={'sm'} fw={'normal'} amount={balances?.lockedStaking} isHidable={true} maxDecimals={6} />}
        </Text>
        <Tooltip label={t('reservedStakingTooltip')} multiline w={300} withArrow data-sentry-element="Tooltip" data-sentry-source-file="Balance.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="Balance.tsx">
            <IoInformationCircleOutline data-sentry-element="IoInformationCircleOutline" data-sentry-source-file="Balance.tsx" />
          </Box>
        </Tooltip>
      </Group>
      <Group mt={'sm'} justify={'space-between'} data-sentry-element="Group" data-sentry-source-file="Balance.tsx">
        <Text fw={'bold'} data-sentry-element="Text" data-sentry-source-file="Balance.tsx">
          {t('lockedDemocracy')}
          <MoonTokenAmount span ml={'sm'} fw={'normal'} amount={balances?.lockedDemocracy} isHidable={true} maxDecimals={6} data-sentry-element="MoonTokenAmount" data-sentry-source-file="Balance.tsx" />
        </Text>
        <Tooltip label={t('lockedDemocracyTooltip')} multiline w={300} withArrow data-sentry-element="Tooltip" data-sentry-source-file="Balance.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="Balance.tsx">
            <IoInformationCircleOutline data-sentry-element="IoInformationCircleOutline" data-sentry-source-file="Balance.tsx" />
          </Box>
        </Tooltip>
      </Group>
    </Card>;
}