import { useFlags } from 'flagsmith/react';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { parseJson } from 'utils/json';

export interface OverviewAnnouncement {
  title?: string;
  important?: boolean;
  link?: {
    label?: string;
    url?: string;
    path?: string;
  };
  image?: string;
}

type FlagValue = Record<string | 'global', OverviewAnnouncement[]>;

interface OverviewAnnouncements {
  isEnabled: boolean;
  announcements: OverviewAnnouncement[];
}

function getAnnouncements(
  moonChainKey: string,
  announcements?: string,
): OverviewAnnouncement[] {
  if (!announcements) return [];

  const flagValue: FlagValue = parseJson(announcements) as FlagValue;

  return [...(flagValue['global'] || []), ...(flagValue[moonChainKey] || [])];
}

export function useOverviewAnnouncementsFlag(): OverviewAnnouncements {
  const { overview_announcements: flag } = useFlags(['overview_announcements']);
  const moonChainKey = useMoonChainKeyParam();

  try {
    return {
      isEnabled: flag.enabled,
      announcements: getAnnouncements(moonChainKey, flag.value?.toString()),
    };
  } catch (error) {
    console.error('Error decoding overview announcements', error);

    return {
      isEnabled: false,
      announcements: [],
    };
  }
}
